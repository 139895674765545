.image-carousel {
  height: 115vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

h2 {
  font-size: 3rem;
}

p {
  font-size: 1rem;
}

.container-text-center {
  padding-top: 5vh;
}

.layout-description::before {
  background-color: rgba(0,0,0,0.6);
}

.title-layout {
  background-color: #137544;
  border-radius: 10px;
  padding: 10px;
  width: auto;
  font-size: 2.5rem;
}

.text-center {
  text-align: center;
}

.containervisionmision {
  background-color: #316b4d;
  padding: 1rem;
  margin: 1rem;
  color: white;
  border-radius: 10px;
}

.whatsappButton {
  background-color: #447449 !important;
  color: white;
}

@media only screen and (max-width: 900px) {
  .image-carousel {
    height: 40vh;
  }

  .container-text-center {
    padding-top: auto;
  }  

}
